import React from 'react';
import './landing.css'; // Ensure this imports your CSS file
import yappr_logo from './Assets/yappr-logo-v3-green.png';

function LandingPage() {
  return (
    <div className="shell">
      {/* Header */}
      <header className="header">
        <img src={yappr_logo} alt="Yappr Logo" className="logo" />
        <h2 className="app-name">yappr</h2>
      </header>

      {/* Main Content */}
      <main className="main-content">
        <div className="content-wrapper">
          {/* Placeholder for App Screenshot */}
          {/* <div className="screenshot-placeholder">
            <div className="screenshot-placeholder-box">
              <p>App Screenshot Placeholder</p>
            </div>
          </div> */}

          {/* Placeholder for App Explanation Text */}
          <div className="beta-request">
            <p>Email <a href="mailto:getyappr@gmail.com">getyappr@gmail.com</a> to request for the beta.</p>
    
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer style={{padding: 20, backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
        <div className="footer-links">
          <a>Privacy Policy</a>
          <a>Terms</a>
          <a>Community Standards</a>
        </div>
        {/* <div className="beta-request">
          <p>Email <a href="mailto:getyappr@gmail.com">getyappr@gmail.com</a> to request for the beta.</p>
        </div> */}
      </footer>
    </div>
  );
}

export default LandingPage;