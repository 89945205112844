import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './mediaPlayer.css';
import defaultProfilePicture from './Assets/default_profile_picture.jpg';
import ProgressBar from './Components/ProgressBar';
import axios from 'axios';
import HLSPlayer from './Hls';
import AudioPlayer from './AudioPlayer';
import yappr_logo from './Assets/yappr-logo-v3-green.png';

const MediaPlayer = ({ track }) => {
  const { string } = useParams();
  // const [title, setTitle] = useState("");
  const [yap, setYap] = useState(null); 
  const [cookie, setCookie] = useState(null);
  const [liked, setLiked] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const fallbackImage = './Assets/default_profile_picture.jpg';

  useEffect(() => {
    // Make the API call with axios
    console.log("string", string);
    axios.get(`https://api.getyappr.com/pub/${string}`)
      .then((response) => {
        console.log("hello", response.data);
        setYap(response.data.data); // Store the response data
        // setLoading(false); // Turn off the loading state
      })
      .catch((error) => {
        console.log(error);
        // setError(error.message); // Handle any errors
        // setLoading(false);
      });
  }, [string]);


  const toggleLike = () => {
    setLiked(!liked);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    let interval;
    if (playing) {
      interval = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 100) {
            return prevProgress + 0.5; // Update the progress smoothly
          } else {
            clearInterval(interval); // Stop updating once progress reaches 100%
            return 100;
          }
        });
      }, 100); // Adjust the interval time for smoother progress
    } else if (!playing && progress < 100) {
      clearInterval(interval); // Ensure the interval is cleared when paused
    }
    return () => clearInterval(interval); // Cleanup interval on unmount or pause
  }, [playing, progress]);

  return (
    <div className="shell">
      <header className="header">
        <img src={yappr_logo} alt="Yappr Logo" className="logo" />
        <h2 className="app-name">yappr</h2>
      </header>

      <main className="main-content">
        <div className="profile-section">
          {yap && yap.profile && yap.profile.image && (
            <img
              src={yap.profile.image}
              alt="profile picture"
              className="responsive-image"
              onError={(e) => { e.target.src = fallbackImage; }}
            />
          )}

          {yap && yap.title && yap.profile && (
            <div className="info" style={{marginTop: 18}}>
              <h2 className="title">{yap.title}</h2>
              <p className="author-name">{yap.profile.displayName}</p>
              <p className="location">{yap.profile.neighborhood || 'Unknown location'}</p>
            </div>
            
          )}
           <div style={{marginTop: 24}}>
            {yap && <AudioPlayer presignedUrl={yap.file} />}
            </div>
        </div>
       
      </main>

    </div>
  );
};

export default MediaPlayer;
