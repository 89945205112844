import './App.css';
import React from "react";
import trackData from './Data/track2.json'
import MediaPlayer from './MediaPlayer';
import LandingPage from './LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      {/* <MediaPlayer track={trackData} /> */}
      <Router>
      <Routes>
        {/* Route for the home page */}
        <Route path="/" element={<LandingPage/>} />

        {/* Route for a dynamic string */}
        <Route path="/:string" element={<MediaPlayer track={trackData} /> } />
      </Routes>
      </Router>
    </div>


  );
}

export default App;
