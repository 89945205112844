import './audioPlayer.css';
function AudioPlayer({ presignedUrl }) {
    return (
      <div>
        <audio controls>
          <source src={presignedUrl} type="audio/aac" className="responsive-audio"/>
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }
  
  export default AudioPlayer;